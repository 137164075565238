<!--
 * @Description: 
 * @Author: JunLiangWang
 * @Date: 2023-02-28 21:19:45
 * @LastEditors: JunLiangWang
 * @LastEditTime: 2023-08-02 00:10:50
-->
<template>
  <div class="project-container">
    <header>
      <el-select class="sort" v-model="selectTagList" placeholder="请选择" multiple collapse-tags>
        <el-option v-for="tag, index of tagList" :key="index" :label="tag.title" :value="tag.value" />
      </el-select>
      <el-input placeholder="请输入搜索" v-model="search" prefix-icon="el-icon-search" class="search" />
    </header>
    <transition-group class="project-content-container" name="list" tag="div">
      <ProjectItem v-for="item of config.project.projectList" :key="item.id" :img="item.img"
        :time="item[$i18n.locale].time" v-show="
          (item[$i18n.locale].title.indexOf(search) != -1 ||
            item[$i18n.locale].subTitle.indexOf(search) != -1) && isExsitTag(item[$i18n.locale].tag)
        " :tagList="item[$i18n.locale].tag" :title="item[$i18n.locale].title" :subTitle="item[$i18n.locale].subTitle"
        :buttonList="item[$i18n.locale].button" class="project-content-item" />
    </transition-group>
  </div>
</template>

<script>
import ProjectItem from "@/components/project-item/index.vue";
export default {
  name: "project",
  components: {
    ProjectItem,
  },
  data() {
    return {
      search: "",
      selectTagList: []
    };
  },
  computed: {
    tagList() {
      let tempMap = new Map()
      let list = []
      for (const item of this.config.project.projectList) {
        for (const tag of item[this.$i18n.locale].tag) {
          if (tempMap.get(tag.value) == undefined) {
            tempMap.set(tag.value, true)
            list.push(tag)
          }
        }
      }
      return list
    }
  },
  methods:{
    isExsitTag(tagList) {
      if(this.selectTagList.length==0)return true
      for (const tag of tagList) {
        if (this.selectTagList.indexOf(tag.value) != -1) return true
      }
      return false
    }
  }
};
</script>
<style lang="scss" scoped>
.project-container {
  max-width: 1080px;
  width: 100%;

  header {
     margin-top: 40px;
     margin-bottom: 20px;
    // margin: 30px 0;
    padding: 0 25px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;

    .sort {
      width: 170px;
      margin-right: 20px;
    }

    .search {
      width: 200px;
    }
  }
}

.project-content-container {
  height: 230px;
  font-size: 14px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  position: relative;
}

.project-content-item {
  width: 50%;
  z-index: 100;
}

@media only screen and (max-width: 950px) {
  .project-content-container {
    height: 25vw;
    font-size: 1.4vw;
  }
}

@media only screen and (max-width: 730px) {
  .project-content-container {
    height: 45vw;
    font-size: 2vw;
  }

  .project-content-item {
    width: 100%;
  }

  .project-container header .search {
    width: calc(100% - 190px)
  }
}

.list-enter-active,
.list-leave-active {
  transition: all 0.6s;
}

.list-enter,
.list-leave-to

/* .list-leave-active for below version 2.1.8 */
  {
  opacity: 0;
  transform: translateX(-30px);
}

.list-move {
  position: relative;
}

.list-leave-active,
.list-leave-to {
  z-index: 0;
  position: absolute;
}
</style>
<style>
.project-container .el-input__suffix,
.project-container .el-input__prefix,
.project-container .el-input__icon,
.project-container .el-select__tags,
.project-container .el-select__tags-text {
  background: none;
}

.project-container .el-input__inner,
.project-container .el-select .el-input.is-focus .el-input__inner,
.project-container .el-select .el-input__inner:focus {
  border-radius: 8px;
  border: solid 2px #d4d4d4;
}

.project-container .el-input__icon {
  color: #c0c4cc;
  font-weight: bold;
}
</style>