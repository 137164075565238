<!--
 * @Description: 项目列表
 * @Author: JunLiangWang
 * @Date: 2023-03-08 22:39:58
 * @LastEditors: JunLiangWang
 * @LastEditTime: 2023-03-09 17:02:55
-->
<template>
  <section>
    <div class="project-item-container">
      <img :src="img" />
      <aside>
        <div class="date-container">
          <span>{{ time }}</span>
          <span class="tag-list">
            <el-tag v-for="(tag, index) of tagList" :key="index" :type="tag.type" size="mini" effect="plain">{{ tag.title
            }}</el-tag>
          </span>
        </div>
        <div class="title-container">
          <p class="title">{{ title }}</p>
          <p class="subtitle">{{ subTitle }}</p>
        </div>
        <div class="btn-container">
          <a class="btn" :style="'background:' + button.background + ';'" v-for="(button, index) of buttonList"
            :key="index" :href="button.link" target="_blank">
            <i :class="button.icon" :style="'color:' + button.color + ';'" />
            <span :style="'color:' + button.color + ';'">{{ button.title }}</span>
          </a>
        </div>
      </aside>
    </div>
  </section>
</template>

<script>
export default {
  name: "ProjectItem",
  props: {
    img: {
      type: String,
      default: "",
    },
    time: {
      type: String,
      default: "",
    },
    tagList: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: "",
    },
    subTitle: {
      type: String,
      default: "",
    },
    buttonList: {
      type: Array,
      default: () => []
    }
  },
};
</script>

<style lang="scss" scoped>
section{
  height: 100%;
  width: 100%;
  background-image: linear-gradient(to right,
      rgb(152, 152, 152) 0 10%,
      rgba(255, 255, 255, 0) 10%);
  background-position: bottom;
  background-size: 8px 1px;
  background-repeat: repeat-x;
}

.project-item-container {
  height: 100%;
  width: 100%;
  cursor: pointer;
  padding: 25px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 3fr 4fr;
  grid-template-rows: 100%;
  grid-column-gap: 20px;
}

.project-item-container,.project-item-container *{
  background: transparent;
}
.project-item-container:hover {
  background: #6b6b6b2e;
}

.project-item-container>img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.project-item-container>aside {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.date-container {
  display: flex;
  justify-items: flex-end;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.date-container * {
  font-size: 1em;
  margin-bottom: 10px;
}

.tag-list>.el-tag {
  margin-right: 5px;
}

.tag-list>.el-tag:nth-last-child(1) {
  margin-right: 0;
}

.title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  margin: 0;
  font-weight: 600;
  font-size: 2.2em;
}

.subtitle {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  font-size: 0.9em;
}

.btn-container {
  width: 100%;
  height: 34px;
  display: flex;
  flex-wrap: nowrap;
}

.btn {
  width: 100%;
  height: 100%;
  background: black;
  border-radius: 6px;
  margin-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
}
.btn:first-child {
  margin-left: 0;
}
.btn:hover {
  opacity: 0.7;
}
.btn>i{
  margin-right: 5px;
}
.btn *{
  font-size: 0.8em;
}
</style>